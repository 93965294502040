import styled, { css } from "styled-components";

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  algin-items: center;
  height: 450px;
  background: linear-gradient(90deg, #134E5E 0%, #246064 18.23%, #407E6E 47.92%, #4C8B73 60.94%, #559476 69.79%, #71B280 100%);
  width: 100%;
`;

export const BannerTextContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 620px) {
    padding: 0 15px;
  }
`;

export const BannerHeader = styled.div`
  & * {
    font-family: "Jarkarta", sans-serif !important;
  }
  font-size: 25px;
  line-height: 1.3;
  text-align: center;
  color: white;

  @media screen and (max-width: 535px) {
    font-size: 20px;
  }
`;

export const DesktopBannerText = styled.p`
  display: none;
  @media screen and (min-width: 560px) {
    display: block;
  }
`
export const MobileBannerText = styled.p`
  display: none;
  @media screen and (max-width: 559px) {
    display: block;
  }
`
export const Bold = styled.span`
  font-weight: 500;
`

export const BigSearcherWrapper = styled.div`
  width: 750px;
  & > * {
    max-width: 100%;
  }

  @media screen and (max-width: 790px) {
    width: 590px;
  }

  @media screen and (max-width: 620px) {
    width: 100%;
  }
`

export const FeaturedListingsContainer = styled.div`
  padding: 0 20px;
  margin: auto;
  max-width: 1440px;
`

export const PopularOnZonado = styled.div`
  flex: 0 0 310px;
  background: rgba(237,237,237,0.5);
  padding: 20px 30px;
  margin-top: 43px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`
export const WhatsOnZonado = styled.div`
  padding: 0 20px;
  margin: 40px auto 0px;
  max-width: 1440px;
  width: 100%;
`

export const PropertyTypeCardWrapper = styled.div`
  display: flex;  
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  flex-wrap: no-wrap;
  overflow-x: auto;
  padding-bottom: 20px;
`

export const PropertyTypeCard = styled.a`
  display: block;
  border-radius: 6px;
  flex: 0 0 210px;
  height: 250px;
  background: linear-gradient(242.85deg, rgba(0, 0, 0, 0) 0%, #134E5E 100%), url(${({ img }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 900px) {
    height: 220px;
    flex: 0 0 150px;
  }
`
export const PropertyTypeCardText = styled.p`
  color: #fff;
  font-size: 28px;
  font-weight: 500;

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
`
export const PropertyTypeCardLink = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
`

export const NewListingsContainer = styled.div`
  padding: 0 20px;
  margin: 60px auto 0;
  max-width: 1440px;
`
export const NewListingTabs = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  // overflow-x: auto;
  row-gap: 20px;
  flex-wrap: wrap;
`
export const NewListingTab = styled.div` 
  font-size: 18px;
  cursor: pointer;
  color: #333;
  position: relative;
  flex: 0 0 auto;
  ${
    (({ active }) => {
      if (active) {
        return css`
          color: #125F3F;

          &::after {
            content: "";
            position: absolute;
            height: 3px;
            width: 100%;
            left: 0;
            bottom: -9px;
            background: #125F3F;
          }
        ` 
      }
    })
  }
`
export const NewListingsGridContainer = styled.div`
  & > * {
    padding: 0;
  }

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  margin-top: 30px;
  justify-content: space-between;
  max-width: 1440px;

  @media screen and (max-width: 1055px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  @media screen and (max-width: 570px) {
    grid-template-columns: minmax(0, 1fr);
  }
`
export const NewListingsGridItem = styled.div`

`

export const ViewAllButton = styled.button`
  display: block;
  width: 146px;
  height: 60px;
  margin: 20px auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #134E5E 0%, #246064 18.23%, #407E6E 47.92%, #4C8B73 60.94%, #559476 69.79%, #71B280 100%);
  color: #fff;
  column-gap: 5px;
`
export const ViewAllBtnIcon = styled.div`
  margin-top: 2px;
`

export const TestimonialContainer = styled.div`
  width: 680px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;

  @media screen and (max-width: 900px) {
    width: 90%;  
    align-items: flex-start;
  }
`
export const TestimonialText = styled.div`
  font-size: 28px;
  text-align: center;
  @media screen and (max-width: 900px) {
    text-align: left;
    font-size: 20px;
  }
`
export const TestimonialAuthor = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 10px;
`
export const CompanyLogo = styled.div`
  flex: 0 0 40px;
`
export const TestimonialName = styled.p`
  margin-bottom: 15px;
  line-height: 0px;
`
export const TestimonialRole = styled.p`
`
// export const MiniPropertyCard = styled.div`
//   display: flex;
//   flex: 0 0 auto;
//   position: relative;
//   justify-content: center;
//   width: 128px;
//   height: 160px;
//   & > p {
//     width: 100%;
//     text-align: center;
//     font-size: 16px;
//     color: white;
//     position: absolute;
//     bottom: 0;
//     font-weight: 600;
//     line-height: 28px;
//     letter-spacing: 1px;
//     padding-bottom: 7px;
//     background: linear-gradient(
//       180deg,
//       rgba(196, 196, 196, 0) 20.46%,
//       #1a1a1a 100%
//     );
//     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
//   }
//   @media (min-width: 768px) {
//     width: 196px;
//     height: 246px;
//     & > p {
//       font-size: 18px;
//     }
//   }
// `;

export const StatsBannerContainer = styled.div`
  padding: 78px 0px;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  background-color: #fbfaf6;
  margin: 0 auto;
  margin-top: 291px;
`;

export const SectionHeader = styled.h1`
  font-weight: 300;
  font-size: 30px;
  letter-spacing: -0.3px;
  line-height: 38px;
  text-align: center;
  color: black;
  padding-bottom: 90px;
  @media (min-width: 772px) {
    font-size: 40px;
    letter-spacing: -0.4px;
    line-height: 48px;
  }
  @media (min-width: 940px) {
    font-size: 44px;
    letter-spacing: -0.44px;
    line-height: 52px;
  }
`;

export const SectionTitle = styled.p`
  font-size: 22px;

  @media screen and (max-width: 750px) {
    font-size: 18px;
  }
`

export const StatsCardContainer = styled.div`
  display: grid;
  gap: 59px 100px;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 90px;
  margin: 0 auto;
  @media (min-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StatsTextContainer = styled.div`
  margin-top: 15px;
  & > p:nth-of-type(1) {
    color: black;
    margin-bottom: 17px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 38px;
    @media (min-width: 772px) {
      font-size: 40px;
      letter-spacing: -0.4px;
      line-height: 48px;
    }
    @media (min-width: 1242px) {
      font-size: 44px;
      letter-spacing: -0.44px;
      line-height: 52px;
    }
  }
  & > p:nth-of-type(2) {
    color: black;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
`;

export const SummaryBannerContainer = styled.div`
  padding: 78px 0px;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  background-color: #fbfaf6;
  margin: 0 auto;
  margin-bottom: 85px;
  @media (min-width: 814px) {
    margin-bottom: 170px;
  }
`;

export const SummaryCardContainer = styled.div`
  display: grid;
  gap: 55px 24px;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 90px;
  margin: 0 auto;
  & > * {
    max-width: 310px;
  }
  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1390px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const SummaryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  & > p:nth-of-type(1) {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    line-height: 30.24px;
    margin-bottom: 8px;
    text-align: center;
  }
  & > p:nth-of-type(2) {
    color: #383838;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

export const SummaryButtonContainer = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 0 auto;
  gap: 30px;
  flex-wrap: wrap;
  & > a {
    flex-grow: 1;
    flex-basis: 151px;
  }
  & > a > button {
    width: 100%;
  }
  @media (min-width: 553px) {
    & > a > button {
      width: 151px;
    }
  }
`;

export const AboutBannerContainer = styled.div`
  max-width: 1400px;
  padding: 78px 20px;
  margin: 0 auto;
  margin-bottom: 85px;
  display: flex;
  flex-direction: column;
  background-color: #fbfaf6;
  @media (min-width: 814px) {
    margin-bottom: 170px;
  }
`;

export const AboutTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 105px;
  & > p:nth-child(2) {
    margin-top: 32px;
  }
`;

export const AboutText = styled.p`
  text-align: center;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
  font-size: 16px;
  line-height: 35.2px;
  color: black;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 39.2px;
  }
  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 43.2px;
  }
  @media (min-width: 1200px) {
    font-size: 28px;
    line-height: 47.2px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 51.2px;
  }
`;

export const PhoneCardVectors = styled.div`
  background: url("/images/landing/left-lines.svg") left top no-repeat;
  padding-top: 100px;
  margin-bottom: 85px;
  @media (min-width: 1800px) {
    background: none;
    padding: 0;
  }
`;

export const PhoneCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 17px 30px 30px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  @media (min-width: 960px) {
    padding: 37px 50px 50px;
  }
`;

export const PhoneCardHeader = styled.h1`
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 28px;
  line-height: 43px;
  @media (min-width: 772px) {
    font-weight: 600;
    font-size: 40px;
    line-height: 61px;
  }
`;

export const PhoneCardSubtitle = styled.p`
  margin-bottom: 30px;
  padding-bottom: 16px;
  border-bottom: 2px solid #e4e7e9;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 772px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const PhoneCardRowHeader = styled.h1`
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 772px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
`;

export const PhoneRowText = styled.p`
  max-width: none;
  font-size: 16px;
  line-height: 22px;
  & > a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  @media (min-width: 960px) {
    max-width: 530px;
  }
`;
export const PopularSearchCard = styled.div`
  width: 313px;
  height: 257px;
  margin: 2px 0;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  &:hover {
    & > p {
      text-decoration: underline;
      text-decoration-color: #4a4a4a;
    }
  }
  & > p {
    font-size: 16px;
  }
  @media (min-width: 480px) {
    width: 323px;
    height: 267px;
  }
  @media (min-width: 786px) {
    width: 323px;
    height: 267px;
    & > p {
      font-size: 18px;
    }
  }
  @media (min-width: 814px) {
    width: 343px;
    height: 297px;
  }
  @media (min-width: 960px) {
    width: 353px;
    height: 307px;
  }
  @media (min-width: 1200px) {
    width: 363px;
    height: 307px;
  }
  @media (min-width: 1440px) {
    width: 383px;
    height: 317px;
  }
`;

export const CompaniesContainer = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 170px;
`;

export const CompanyImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 44px 22px;
  flex-basis: 33%;
  max-width: 780px;
  margin: 0 auto;
  & > * {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  & > div > img {
  }
  & > div {
    height: fit-content;
    max-width: 100px;
  }
  @media (min-width: 480px) {
    & > div {
      max-width: 120px;
    }
  }
  @media (min-width: 626px) {
    & > div {
      max-width: 242px;
    }
  }
  @media (min-width: 786px) {
    flex-wrap: nowrap;
    gap: 22px;
    flex-basis: 100%;
  }
`;

export const ScrollContainer = styled.div`
  padding-bottom: 20px;
  ${(props) => {
    if (props.perf) {
      return css`
        & > div {
          max-width: 241px;
          margin-top: 2px;
          @media (min-width: 480px) {
            max-width: 301px;
          }
          @media (min-width: 786px) {
            max-width: 361px;
          }
        }
      `;
    }
  }};
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  & > * {
    flex: 0 0 auto;
    margin: 0 20px;
  }
`;

export const TypesWithListingsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 30px 50px;
  margin: 20px 0px;
  
  @media screen and (max-width: 1155px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 10px;
  }
  @media screen and (max-width: 720px) {
    gap: 15px 10px;
    & button#listingTypeBtn {
      font-size: 14px;
    }

  }
  @media screen and (max-width: 415px) {
    & button#listingTypeBtn {
      font-size: 13px;
    }
  }
`
export const CollapsibleFooterListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;  
  flex-wrap: wrap;
  gap: 20px 60px;
  
  & > * {
    flex: 1;
  }

  // display: grid;
  // grid-auto-columns: repeat();
  // grid-template-rows: 1fr;
  // grid-gap: 20px 60px;
  
  margin-bottom: 90px;
`

export const TopBanner = styled.section`
    position: relative;
    z-index: 2;
    /* padding-top: 152px; */
    color: #374457;
    background: url('/images/landing/backgroundGD.svg') no-repeat center top 0px;
    background-size: 100%;
    padding: 109px 0 64px;

    @media (max-width: 1092px) {
      padding: 80px 0 15px;
    }


    @media (max-width: 768px) {
      padding: 40px 0 15px;
    }


`

export const TopBannerWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
    padding: 0 20px;

`

export const BannerRow = styled.div`
  display: flex;
`

export const ColContent = styled.div`
  width: 49%;
  padding-right: 8%;

  @media (max-width: 1092px) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
`

export const ColMedia = styled.div`
    margin-right: calc((100% - 100vw)/2);
    padding-left: 5px;
`

export const FBLabel = styled.span`
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    color: #101828;
    padding: 6px 10px;
    border: 1px solid #101828;
    border-radius: 4px;
    margin: 0 0 20px;
    display: inline-flex;
    letter-spacing: 0.9px;
`

export const Slogan = styled.h1`
@media (max-width) {
  font-size: 32px;
}
  font-size: 35px;
  margin-bottom: 32px;
  line-height: 1.1;
  /* letter-spacing: -0.17em; */
  letter-spacing: -1px;
  font-weight: 700;

  @media (max-width: 768px) {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  @media (min-width: 1268px) {
    font-size: 55px;
  }
`

export const ImageBlock = styled.div`
  display: none;
  max-width: 600px;

  @media (max-width: 1092px) {
    display: block;
    padding: 0 5px;
    margin: 17px auto 37px;
  }
`

export const ImageEl = styled.img`
    max-width: 100%;
    height: auto;

`

export const BelowImageText = styled.div`
    font-size: 22px;
    margin-bottom: 34px;
    font: 18px/1.52 Inter,sans-serif;
    margin: 0 0 22px;
    letter-spacing: -.02em;
    color: #475467;

    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      font-family: Inter;
      letter-spacing: 0;
      margin-bottom: 24px;
    }
`

export const ListHolder = styled.div`
  margin-bottom: 40px;
  color: #374457;

`

export const IconsList = styled.ul`
  @media (max-width: 1210px) {
    max-width: 440px;
    margin-left: 0;
    margin: 0 auto;
    text-align: left;
    font-family: Inter, sans-serif;
    list-style: none;
    padding: 0;
  }
`

export const IconItem = styled.li`
  letter-spacing: -.2px;
  display: flex;
  margin: 0 0 11px;
  position: relative;
  padding-left: 28px;

  &::before {
    position: absolute;
    left: 0px;
    top: 5px;
    color: rgb(255, 255, 255);
    font-size: 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 50%;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjMgMC4zTDUgOS42TDEuNyA2LjNDMS4zIDUuOSAwLjcgNS45IDAuMyA2LjNDLTAuMSA2LjcgLTAuMSA3LjMgMC4zIDcuN0w0LjMgMTEuN0M0LjUgMTEuOSA0LjcgMTIgNSAxMkM1LjMgMTIgNS41IDExLjkgNS43IDExLjdMMTUuNyAxLjdDMTYuMSAxLjMgMTYuMSAwLjcgMTUuNyAwLjNDMTUuMyAtMC4xIDE0LjcgLTAuMSAxNC4zIDAuM1oiIGZpbGw9IiMyREFBNEEiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
  }
`

export const ButtonWrapper = styled.div`

`

export const ButtonAnchor = styled.a`
    font-family: Inter, sans-serif;
    font-weight: 500;
    padding: 12px 24px;
    letter-spacing: -.2px;
    margin: 0 0 30px;
    color: #fff;
    background-color: #125f3f;
    border-color: #125f3f;
    border-radius: 44px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5;line-height: 1.5;border: 1px solid transparent;user-select: none;
`

export const ImageBlockDesktop = styled.div`
  @media (max-width: 1092px) {
    display: none;
  }
  max-width: 600px;

  display: block;
`


export const BenefitsWrapper = styled.div`
    display: grid;
  padding: 0 20px;

    grid-template-rows: auto;
    max-width: 1440px;
    width: 100%;
    /* grid-template-columns: repeat(12,minmax(0,1fr)); */
    grid-column-gap: 24px;
    /* margin-left: 16px;
    margin-right: 16px; */
    height: 100%;
    position: relative;

    /* @media (min-width: 960px) {
        margin-left: 32px;
        margin-right: 32px;
    } */

    
    /* @media (min-width: 1440px) {
        margin-left: 40px;
        margin-right: 40px;
    } */
    
    @media (min-width: 1600px) {
        margin-left: 64px; 
        margin-right: 64px;
    }

`

export const StatsSection = styled.section`
    overflow: hidden;
    padding: 2rem 0;

    @media (min-width: 768px) {
    padding: 4rem 0;
    }
`

export const StatTitle = styled.div`
    text-align: left;
    box-sizing: border-box;
    align-items: center;
    color: #1a202c;
    display: flex;

    font-size: 32px;
    justify-content: center;

    @media (min-width: 768px) {
    font-size: 35px;
    justify-content: flex-start;
}
`


export const StatDesc = styled.div`
  
    margin-top: 16px;
    color: #1a202c;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.5;
`
